import React from 'react';

import HowItWorks from './HowItWorks';
import Personalized from './Personalized';

const Demo = () => (
  <div data-testid="demo" id="demo">
    <HowItWorks />
    <Personalized />
  </div>
);

export default Demo;

import React from 'react';

import Image from '../../../Components/Image/Image';

import PERSONALIZE_IMG_PNG from '../../../Assets/Demo/PERSONALIZE_IMG.png';
import PERSONALIZE_IMG_WEBP from '../../../Assets/Demo/PERSONALIZE_IMG.webp';

import { PERSONALIZE_TEXT_1, PERSONALIZE_TEXT_2 } from '../../../Constants';

import styles from './personalize.module.css';

const Personalized = () => (
  <div className={`${styles.container} center`}>
    <div className={`${styles.text_box}`}>
      <p className={`heading_text`}>{PERSONALIZE_TEXT_1}</p>

      <p className={`body_text`}>{PERSONALIZE_TEXT_2}</p>
    </div>
    <div className={`${styles.image_box}`}>
      {/* <img
        className={styles.mobile_image}
        src={PERSONALIZE_IMG}
        alt="mobile_image"
      /> */}
      <Image
        className={styles.mobile_image}
        src={PERSONALIZE_IMG_PNG}
        webpImage={PERSONALIZE_IMG_WEBP}
        alt="mobile_image"
      />
    </div>
  </div>
);

export default Personalized;

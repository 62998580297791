import React from 'react';

import Modal from '../../../Components/Modal/Modal';
import Video from '../../../Components/Video/Video';
import Icon from '../../../Components/Icon/Icon';

import { VIDEO_TEXT_1, VIDEO_TEXT_2 } from '../../../Constants';
import SOCIANA_VIDEO from '../../../Assets/Demo/sociana_demo.mp4';

import styles from './howitworks.module.css';

class HowItWorks extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
  }

  showModal = () => {
    this.setState({ isVisible: true });
  };

  hideModal = () => {
    this.setState({
      isVisible: false
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={`${styles.content} center`}>
          <span className={`heading_text white_heading`}>{VIDEO_TEXT_1}</span>

          <p className={`body_text`}>{VIDEO_TEXT_2}</p>
          <Icon icon="play-circle" size="5x" onClick={this.showModal} />

          <Modal show={this.state.isVisible}>
            <Video
              loop
              controls
              autoPlay
              src={SOCIANA_VIDEO}
              hideModal={this.hideModal}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default HowItWorks;

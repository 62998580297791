import React from 'react';
import styles from './video.module.css';

const Video = ({ hideModal, src, ...otherProps }) => (
  <div className={`${styles.container} center`}>
    <button className={styles.close_button} type="button" onClick={hideModal}>
      x
    </button>
    <video className={styles.video} {...otherProps}>
      <source src={src} type="video/mp4" />
    </video>
  </div>
);

export default Video;
